import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { marked } from 'marked'
import { window, document } from 'browser-monads'
import { graphql } from 'gatsby'

import { hero } from '../../images/faq'

import Dropdown from '../../components/Dropdown'
import SEO from '../../components/SEO'
import withContext from '../../lib/withContext'

class FAQPage extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      activeID: '',
      collapsed: true
    }

    this.setActive = this.setActive.bind(this)
    this.setCollapsed = this.setCollapsed.bind(this)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  setActive(event) {
    let { id } = event.target
    const { activeID } = this.state

    if (id === activeID) {
      id = ''
    }

    this.setState({ activeID: id })
  }

  setCollapsed() {
    this.setState(({ collapsed }) => ({
      collapsed: !collapsed
    }))
  }

  scrollToFAQSection = (id) => {
    const target = document.querySelector(`#${id}`)
    const scrollToTarget = target.offsetTop
    window.scrollTo(0, scrollToTarget)
  }

  handleScroll = () => {
    const scrollPos = window.pageYOffset
    const cats = document.getElementById('categories').childNodes

    cats.forEach((item) => {
      const link = item.querySelector('a').getAttribute('data-href')
      const target = link.substr(1)
      const refElem = document.getElementById(target)
      const refTop = refElem.offsetTop - 120
      const refHeight = refElem.getBoundingClientRect().height + 128

      if (refTop <= scrollPos && refTop + refHeight > scrollPos) {
        item.classList.add('active')
      } else {
        item.classList.remove('active')
      }
    })
  }

  render() {
    const {
      data: {
        allContentfulFaqCategory: {
          edges
        }
      }
    } = this.props
    const { activeID } = this.state

    const metaData = {
      title: 'FAQ',
      image: undefined
    }

    return (
      <div>
        <SEO metaData={metaData} />
        <main className="faq animated fadeInPage">
          <header className="header padding-xxl">
            <div className="icons">
              <img src={hero} alt="Hero" />
            </div>
            <div className="container-md relative">
              <div className="grid yg align-middle">
                <div className="col-10">
                  <h1>Have a question about Jollycons?</h1>
                  <p className="streamer">Check out the most frequently asked questions</p>
                </div>
              </div>
            </div>
          </header>

          <section className="overview margin-l-bottom">
            <div className="container-md">
              <div className="grid">
                <div className="col-3">
                  <ul className="categories" id="categories">
                    {edges
                      .sort((prev, next) => {
                        if (prev.node.customOrder < next.node.customOrder) {
                          return -1
                        } else if (prev.node.customOrder > next.node.customOrder) {
                          return 1
                        }

                        return 0
                      })
                      .map(({ node: cat }, index) => (
                        <li
                          className="category-item"
                          key={index}
                          onClick={() => this.scrollToFAQSection(cat.CategoryID)}
                        >
                          {/* eslint-disable jsx-a11y/anchor-is-valid */}
                          <a data-href={`#${cat.CategoryID}`}>
                            <h6>{cat.name}</h6>
                          </a>
                          {/* eslint-enable jsx-a11y/anchor-is-valid */}
                        </li>
                      ))
                    }
                  </ul>
                </div>

                <div className="col-9 right">
                  {edges.map(({ node: cat }) => (
                    <div
                      className="faq-block margin-xxl-bottom"
                      id={cat.CategoryID}
                      key={cat.id}
                    >
                      <h2>{cat.name}</h2>
                      <p
                        className="subtitle streamer second"
                        dangerouslySetInnerHTML={
                          { __html: marked(cat.subtext.subtext) }
                        }
                      />

                      { cat.questions.map((question, qdx) => (
                        <Dropdown
                          question={question}
                          idx={qdx}
                          setActive={this.setActive}
                          activeID={activeID}
                          key={question.id}
                        />
                      ))}

                    </div>
                  ))}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    )
  }
}

FAQPage.propTypes = {
  data: PropTypes.shape({
    allContentfulFaqCategory: PropTypes.shape({
      edges: PropTypes.any
    })
  })
}

export default withContext(FAQPage)

/**
 * @FIXME Link has been temporarily removed as contentful modifies there api schema if no item sends along the value.
 *
 * The modal should be:
 * questions {
 *   id
 *   questionID
 *   title
 *   content {
 *     content
 *   }
 *   link
 * }
 */
export const faqQuery = graphql`
  query faqQuery{
    allContentfulFaqCategory {
      edges {
        node {
          id
          CategoryID
          name
          tagline,
          customOrder,
          subtext {
            subtext
          }
          questions {
            id
            questionID
            title
            content {
              content
            }
          }
        }
      }
    }
  }
`
