import React from 'react'
import { Collapse } from 'react-collapse'
import { marked } from 'marked'
import PropTypes from 'prop-types'

const Dropdown = ({
  question: {
    questionID,
    title,
    content: {
      content
    }
  },
  idx,
  setActive,
  activeID
}) => (
  <div
    className={`questions-block ${activeID === questionID ? 'open' : ''}`}
    id={questionID}
    key={idx}
  >
    <div className="question">
      <div
        className="question-top"
        onClick={setActive}
        onKeyUp={e => e.keyCode === 13 && setActive(e)}
        id={questionID}
        role="button"
        tabIndex={0}
      >
        <h4>{title}</h4>

        <svg width="14" height="8" viewBox="0 0 14 8">
          <polygon
            fill="#AB56FF"
            points="632.525 33 634 34.394 626.988 41 620 34.393 621.477 33.001 626.991 38.214"
            transform="translate(-620 -33)"
          />
        </svg>
      </div>

      {/* eslint-disable react/no-danger */}
      <Collapse
        isOpened={activeID === questionID}
        aria-hidden={activeID !== questionID}
        style={activeID !== questionID ? { visibility: 'hidden' } : null}
      >
        <p
          dangerouslySetInnerHTML={
            { __html: marked(content) }
          }
        />
      </Collapse>
      {/* eslint-enable react/no-danger */}
    </div>
  </div>
)

Dropdown.propTypes = {
  question: PropTypes.shape({
    questionID: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.object
  }),
  idx: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  setActive: PropTypes.func,
  activeID: PropTypes.string
}

export default Dropdown
